<template>
  <div class="row">
    <div class="col-md-12">
      <b-form @submit.stop.prevent="formOnSubmit" enctype="multipart/form-data">
        <b-card-body title="Setelan umum">
          <b-form-group
            id="input-group-title"
            label="Nama Setelan:"
            label-for="input-title"
          >
            <b-form-input
              id="input-title"
              v-model="form.title"
              placeholder="Nama Setelan"
            ></b-form-input>
            <small class="text-danger">{{ error.title }}</small>
          </b-form-group>

          <b-form-group>
            <b-form-checkbox id="input-status" v-model="form.status" switch>
              Set Default Edit <b>({{ form.status ? "Ya" : "Tidak" }})</b>
            </b-form-checkbox>
          </b-form-group>

          <div class="image-input-wrapper">
            <img
              :src="uploadedImageUrl == '' ? form.logo : uploadedImageUrl"
              alt=""
            />
          </div>

          <b-form-group
            id="input-group-image"
            label="Logo (180 x 62 piksel):"
            label-for="input-image"
          >
            <b-form-file
              v-model="form.logo"
              placeholder="Upload Logo"
              drop-placeholder="Letakkan file di sini..."
              @change="onFileUpload"
            ></b-form-file>
            <small class="text-danger">{{ error.logo }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-emergency-phone"
            label="Nomor Darurat:"
            label-for="input-emergency-phone"
          >
            <b-form-input
              id="input-emergency-phone"
              v-model="form.emergency"
              placeholder="Nomor Darurat"
            ></b-form-input>
            <small class="text-danger">{{ error.emergency }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-support"
            label="Telepon Support 24/7:"
            label-for="input-support"
          >
            <b-form-input
              id="input-support"
              v-model="form.support"
              placeholder="Telepon 24/7"
            ></b-form-input>
            <small class="text-danger">{{ error.support }}</small>
          </b-form-group>
        </b-card-body>

        <b-card-body title="Blok teks">
          <b-form-group
            id="input-group-text-2"
            label="Text Layanan Kami:"
            label-for="input-text-2"
          >
            <b-form-input
              id="input-text-2"
              v-model="form.text_2"
              placeholder="Teks layanan kami"
            >
            </b-form-input>
            <small class="text-danger">{{ error.text_2 }}</small>
          </b-form-group>
        </b-card-body>

        <b-card-body title="Contact Info">
          <b-form-group
            id="input-group-address"
            label="Alamat:"
            label-for="input-address"
          >
            <b-form-textarea
              id="input-address"
              v-model="form.address"
              placeholder="Alamat Lengkap"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.address }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-phone"
            label="Telepon:"
            label-for="input-phone"
          >
            <b-form-input
              id="input-phone"
              v-model="form.phone"
              placeholder="Telepon"
            ></b-form-input>
            <small class="text-danger">{{ error.phone }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-email"
            label="Email:"
            label-for="input-email"
          >
            <b-form-input
              id="input-email"
              v-model="form.email"
              type="email"
              placeholder="Email"
            ></b-form-input>
            <small class="text-danger">{{ error.email }}</small>
          </b-form-group>
        </b-card-body>

        <b-card-body title="Sosial media (Stay Connected)">
          <b-form-group
            id="input-group-youtube"
            label="Youtube:"
            label-for="input-youtube"
          >
            <b-form-input
              id="input-youtube"
              v-model="form.youtube_id"
              placeholder="Youtube"
            >
            </b-form-input>
            <small class="text-danger">{{ error.youtube_id }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-twitter"
            label="Twitter:"
            label-for="input-twitter"
          >
            <b-form-input
              id="input-twitter"
              v-model="form.twitter_id"
              placeholder="Twitter"
            >
            </b-form-input>
            <small class="text-danger">{{ error.twitter_id }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-instagram"
            label="Instagram:"
            label-for="input-instagram"
          >
            <b-form-input
              id="input-instagram"
              v-model="form.instagram_id"
              placeholder="Instagram"
            >
            </b-form-input>
            <small class="text-danger">{{ error.instagram_id }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-facebook"
            label="Facebook:"
            label-for="input-facebook"
          >
            <b-form-input
              id="input-facebook"
              v-model="form.facebook_id"
              placeholder="Facebook"
            >
            </b-form-input>
            <small class="text-danger">{{ error.facebook_id }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-linkedin"
            label="Linkedin:"
            label-for="input-linkedin"
          >
            <b-form-input
              id="input-linkedin"
              v-model="form.linkedin_id"
              placeholder="Linkedin"
            >
            </b-form-input>
            <small class="text-danger">{{ error.linkedin_id }}</small>
          </b-form-group>

          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            class="ml-2"
            variant="danger"
            type="button"
            @click="btnCancelOnclick"
          >
            Batal
          </b-button>
        </b-card-body>
      </b-form>
    </div>
  </div>
</template>

<style scoped>
.image-input-wrapper {
  width: 180px !important;
  height: 62px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import CrudModule from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      error: {
        title: "",
        address: "",
        phone: "",
        emergency: "",
        support: "",
        email: "",
        text_1: "",
        text_2: "",
        text_3: "",
        facebook_id: "",
        twitter_id: "",
        instagram_id: "",
        youtube_id: "",
        linkedin_id: "",
        status: "",
        logo: "",
      },
      uploadedImageUrl: "",
    };
  },

  methods: {
    btnCancelOnclick() {
      this.$root.$emit("cancelWebsiteSettingUpdate");
      var self = this;
      Object.keys(this.error).forEach(function (key, index) {
        if (key === "logo") {
          self.error[key] = "";
        } else {
          self.error[key] = "";
        }
      });
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.logo = evt.target.files[0];
      console.log("logo", this.form.logo);
    },

    async formOnSubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (key === "status") {
          formData.append(key, value ? 1 : 0);
        } else {
          formData.append(key, value);
        }
      }
      let response = await CrudModule.submit(formData, this.route);
      console.log("response", response);
      if (response.state == "error") {
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        var self = this;
        Object.keys(this.error).forEach(function (key, index) {
          if (key === "logo") {
            self.error[key] = "";
          } else {
            self.error[key] = "";
          }
        });
        this.uploadedImageUrl = "";
        this.$root.$emit("updateWebsiteSetting");
        Swal.fire(response.success.title, response.success.message, "success");
      }
    },
  },
};
</script>
